import React, { useState, useEffect } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import request from './custom/request';
import ReactPlayer from 'react-player'
import EZUIKit from 'ezuikit-js';
import { SetMapChoose } from './custom/common';

export default (props) => {

    let { ckey, choose, setChoose, chooseDeviceIndex, setChooseDeviceIndex } = props;
    let key = ckey;
    let [circleMarkers, setCircleMarkers] = useState([]);

    //console.log('Memu', props)
    const isChoose = key === choose;

    let svgIcon = '<svg t="1655818074737" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4133" width="30" height="30" style="fill:#f13333"><path d="M512.434393 66.861804c-0.054235 0-0.109494 0-0.163729 0l-0.541329 0c-0.056282 0-0.109494 0-0.163729 0-191.363376 0.434905-346.930477 156.1115-346.930477 347.637582 0 188.810227 119.553886 338.510714 326.018192 532.535709 0.325411 0.434905 0.434905 0.977257 0.814552 1.302669 3.042289 3.260254 6.735401 5.432733 10.646478 6.843872 0.923022 0.325411 1.954515 0.325411 2.933819 0.543376 2.226714 0.542352 4.399193 1.412163 6.681166 1.412163 0.107447 0 0.161682-0.10847 0.270153-0.10847 0.109494 0 0.163729 0.10847 0.272199 0.10847 2.281973 0 4.455475-0.86981 6.681166-1.412163 0.977257-0.217964 2.010797-0.217964 2.933819-0.543376 3.90903-1.412163 7.605212-3.584642 10.646478-6.843872 0.379647-0.325411 0.488117-0.868787 0.813528-1.302669 206.464305-194.024995 326.019215-343.725482 326.019215-532.535709C859.364871 222.973304 703.796746 67.296709 512.434393 66.861804zM511.998465 596.247776c-101.193727 0-183.541223-82.347496-183.541223-183.596482 0-101.247962 82.347496-183.486988 183.541223-183.486988 101.195773 0 183.541223 82.238003 183.541223 183.486988C695.539688 513.901303 613.194238 596.247776 511.998465 596.247776z" p-id="4134"></path></svg>';
    let svgIcon2 = '<div style="position: absolute;top: 5px;left: 9px;height: 13px;width: 12px;background: #f13333;"></div>';
    let svgIcon3 = '<svg t="1655818744346" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5704" width="20" height="20" style="position: absolute;top: 2px;left: 5px;fill: #FFF;"><path d="M98.461538 546.953846c0-12.228923 9.176615-18.215385 20.401231-13.272615l86.390154 37.927384c11.224615 4.923077 20.401231 18.944 20.401231 31.172923v49.368616h144.738461l27.963077-60.435692 85.543385 39.778461-40.448 87.433846a47.241846 47.241846 0 0 1-42.870154 27.313231h-174.966154v49.368615c0 12.248615-9.156923 26.249846-20.401231 31.172923l-86.350769 37.96677C107.638154 869.651692 98.461538 863.704615 98.461538 851.456z m814.67077 3.328l-58.978462 126.070154-84.381538-39.227077 143.36-86.843077zM361.491692 161.673846L947.593846 434.136615c22.193231 10.358154 23.236923 29.184 2.284308 41.865847l-262.833231 159.232c-20.952615 12.681846-56.280615 14.611692-78.513231 4.292923l-386.500923-179.672616a44.563692 44.563692 0 0 1-21.582769-59.076923l101.769846-217.6a44.780308 44.780308 0 0 1 59.273846-21.504z" p-id="5705"></path></svg>';

    const { data, isLoading, isError, isRefetching, isRefetchError, refetch } =
        useQuery(`MonitorList`, () => request.get('/Monitor/GetList', { params: {} }), {
            onSuccess: (data) => {
                try {
                    circleMarkers.forEach((item) => {
                        window.global.map.remove(item);
                    })
                    let circleMarkerArr = [];
                    data.data.Result.map((item, index) => {
                        let { Location, DeviceName, Status, id } = item;
                        if (Location.length > 0) {
                            // var circleMarker = new window.global._AMap.CircleMarker({
                            //     center: eval(`new window.global._AMap.LngLat(${Location})`),
                            //     radius: 10 + Math.random() * 1,//3D视图下，CircleMarker半径不要超过64px
                            //     strokeColor: 'white',
                            //     strokeWeight: 2,
                            //     strokeOpacity: 0.5,
                            //     fillColor: 'rgba(255,0,0)',
                            //     fillOpacity: Status && Status.Status === 1 ? 0.8 : 0.2,
                            //     zIndex: 9999,
                            //     bubble: true,
                            //     cursor: 'pointer',
                            //     clickable: true
                            // })
                            var circleMarker = new window.global._AMap.Marker({
                                content: `<div Location='${Location}' class='mapDian_ mapDian_Monitor_${index}' style="position:relative;height:30px;overflow:hidden;">${svgIcon}${svgIcon2}${svgIcon3}</div>`,  // 自定义点标记覆盖物内容
                                position: eval(`new window.global._AMap.LngLat(${Location})`),
                                anchor: 'bottom-center',
                                title: DeviceName,
                                //animation:'AMAP_ANIMATION_BOUNCE',
                                //offset: new window.global._AMap.Pixel(-15, -30) // 相对于基点的偏移位置
                            });

                            circleMarker.on('click', () => {
                                if (isChoose)
                                    setChooseDeviceIndex(index)
                                else
                                    setChoose(key, index);
                                SetMapChoose(`mapDian_Monitor_${index}`);
                            })
                            //circleMarker.setAnimation('AMAP_ANIMATION_BOUNCE')
                            circleMarkerArr.push(circleMarker);
                        }
                    })
                    setCircleMarkers(circleMarkerArr);
                    window.global.map.add(circleMarkerArr);
                    //return true;
                } catch { }
            }, enabled: false
        });

    //const [chooseDeviceIndex, setChooseDeviceIndex] = useState(-1);

    const clickMemu = () => {
        const { Code, Message, Result } = data.data;
        //console.log(data)
        if (!isLoading && !isError && Code == 1 && !isChoose) {
            setChoose(key);
        }
    }
    const closeMemu = () => {
        setChooseDeviceIndex(-1)
        setChoose('');
        SetMapChoose(``);
    }
    const refreshData = () => {
        setChooseDeviceIndex(-1)
        refetch();
    }
    //console.log(isLoading, isError, isRefetching, isRefetchError)
    const getOnlineCount = () => {
        const { Code, Message, Result } = data.data;
        let onlineCount = 0;
        Result.forEach(item => {
            if (item.Status && item.Status.Status === 1) {
                onlineCount++;
            }
        })
        return onlineCount;
    }

    const getOnlineBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            //console.log('getOnlineCount() / Result.length', getOnlineCount() / Result.length)
            return parseInt(getOnlineCount() / Result.length * 100) + "%"
        }
    }
    const getOnlineCountBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            return `${getOnlineCount()}/${Result.length}`;
        }
    }

    useEffect(() => {
        refetch();
    }, [])

    const DataList = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            //console.log('Result',Result)
            return (
                <>
                    {Result && Result.map((item, index) => {
                        let { id, DeviceName, Channels, Status } = item;
                        const online = Status.Status === 1;
                        const chooseItem = index === chooseDeviceIndex;
                        return (
                            <div className={chooseItem ? 'table_tr active' : 'table_tr'} onClick={() => {
                                setChooseDeviceIndex(index);
                                SetMapChoose(`mapDian_Monitor_${index}`);
                            }}>
                                <div className='table_td first'>{id}</div>
                                <div className='table_td f'>{`${DeviceName}(${Channels.length})`}</div>
                                <div className='table_td r'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                        <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        }
    }

    return (
        <div onClick={clickMemu} className={isChoose ? 'menu active' : 'menu'}>
            <div className='iconDiv'>
                <svg t="1653530662334" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1327" width="200" height="200"><path d="M865.024 180h-26.216c38.96-38.144 63.192-91.288 63.192-150V0h-780v420h240V544H174.84c-12.384-34.92-45.728-60-84.84-60H0V784h90c39.112 0 72.456-25.08 84.84-60h252.32C439.544 758.92 472.888 784 512 784c49.624 0 90-40.376 90-90 0-39.112-25.08-72.456-60-84.84V420h240V360h83.024L1024 438.184V101.816L865.024 180zM120 694a30.032 30.032 0 0 1-30 30H60V544h30A30.032 30.032 0 0 1 120 574v120zM427.16 664H180v-60h242v-184h60v189.16A90.424 90.424 0 0 0 427.16 664zM512 724a30.032 30.032 0 0 1-30-30A30.032 30.032 0 0 1 512 664a30.032 30.032 0 0 1 30 30A30.032 30.032 0 0 1 512 724zM722 360h-540V240H692c10.184 0 20.2-0.744 30-2.152V360zM692 180H182V59.8h656.984C825.048 128.184 764.44 180 692 180z m150 120h-60V240h60v60z m122 41.816l-62-30.496v-82.64l62-30.496v143.632zM662 694h60c0 56.016-21.864 108.728-61.568 148.432C620.736 882.136 568.016 904 512 904v-60c39.992 0 77.64-15.624 106.008-43.992 28.368-28.368 43.992-66.016 43.992-106.008z m120 0h60c0 88.016-34.36 170.848-96.76 233.24C682.84 989.64 600.016 1024 512 1024v-60c71.984 0 139.752-28.12 190.816-79.184 51.064-51.064 79.184-118.832 79.184-190.816z" p-id="1328"></path></svg>
            </div>
            {!isLoading ?
                (
                    !isError ?
                        <>
                            <div className='count'>
                                <span>{getOnlineCountBili()}</span>
                            </div>
                            <div className='line'>
                                <div className='line_right' time='6' style={{ width: getOnlineBili()  /*'75%',  '--keyframes': keyframes[0]*/ }}>
                                </div>
                            </div>
                        </>
                        :
                        <div className='err'>Err</div>
                )
                : <div className='loading'>Loading</div>
            }
            {!isLoading && !isError &&
                <>
                    <div className='text'>可视化监控</div>
                    <div className='content'>
                        <div className='content_title'>
                            <div>设备列表</div>
                            <div onClick={closeMemu}>
                                <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                            </div>
                        </div>
                        <div className='content_cont'>
                            <div className='table_tr t'>
                                <div className='table_th'>设备ID</div>
                                <div className='table_th f'>设备名称(监控数)</div>
                                <div className='table_th r'>
                                    <div className='status' onClick={refreshData}>
                                        {isRefetching ?
                                            <span>Refresh</span>
                                            :
                                            <>
                                                <svg style={{ marginRight: 2 }} t="1655482056814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2922" width="18" height="18"><path d="M935.161672 427.51891c-14.511505-11.744485-37.643342-9.155521-49.1627 5.403057l-12.9438 16.20917c-0.926092-5.842055-1.995447-11.625782-3.158946-17.325597C831.326792 245.594511 666.360623 110.434182 477.668077 110.434182c-27.455305 0-55.099922 2.885723-82.198094 8.562003C179.036629 164.405397 39.60195 378.546545 84.655052 596.34499c38.522362 186.222285 203.488531 321.383638 392.229173 321.383638 27.430746 0 55.076386-2.873444 82.174558-8.549723 75.144444-15.746636 144.18589-53.508681 198.288089-108.002806l1.87572-1.662873c1.757017-1.74576 2.778276-3.432169 2.588965-3.443425l1.781576-2.387373c2.137687-3.527336 4.65502-9.191336 4.65502-16.173354 0-17.361413-14.035668-31.479969-31.326473-31.479969-4.275373 0-8.454556 0.914836-12.325723 2.612501l-1.90028-1.318018-8.644891 8.65717c-46.359864 46.478568-104.261599 78.042447-167.484525 91.283006-22.657023 4.750187-45.766346 7.160073-68.684312 7.160073-157.818375 0-295.733445-113.073288-327.96145-268.87268-37.738509-182.291766 78.849836-361.484961 259.918751-399.448598 22.657023-4.750187 45.766346-7.160073 68.708871-7.160073 157.793816 0 295.709909 113.061009 327.96145 268.860401 0.427742 2.101871 0.855484 4.227278 1.258667 6.364965l-13.751189-11.091616c-14.511505-11.768021-37.59627-9.1678-49.1627 5.390777-12.017708 15.056927-9.619078 37.156248 5.343705 49.269124l78.089519 63.1032c0.14224 0.106424 0.285502 0.213871 0.427742 0.332575l3.491521 2.814092 0.712221 0c6.483668 3.657296 15.770172 4.964058 21.065781 4.322445 9.475815-0.890276 17.954931-5.485945 23.940249-12.93152l62.723553-78.659501C952.498526 461.635939 950.052824 439.560154 935.161672 427.51891z" p-id="2923" fill='#FFF'></path></svg>
                                                <span>状态</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <DataList />
                        </div>
                    </div>
                    {isChoose && chooseDeviceIndex >= 0 &&
                        <Right key={'right' + chooseDeviceIndex} cindex={chooseDeviceIndex} cdata={data.data.Result[chooseDeviceIndex]} closeDetail={() => {
                            setChooseDeviceIndex(-1);
                            SetMapChoose(``);
                        }} />
                    }
                </>
            }
        </div>
    )
}

const Right = ({ key, cdata, closeDetail }) => {
    //console.log('Right')
    let { DeviceName, DeviceSerial, DeviceType, Location, Channels, Status, id } = cdata;
    //console.log('Channels', Channels)
    const online = Status && Status.Status === 1;

    let [choose_channelindex, setChoose_channelindex] = useState(0);
    const changeChannel = (i) => {
        setChoose_channelindex(i);
    }

    let [showBigIframe, setShowBigIframe] = useState(false);
    const SetShowBigIframe = (big = true) => {
        setShowBigIframe(big);
    }

    const getLiveUrl = () => {
        let channelNo = Channels[choose_channelindex].ChannelNo;
        //console.log('channelNo',channelNo)
        return request.get('/Monitor/GetLive', { params: { deviceSerial: DeviceSerial, channelNo: channelNo } })
    }
    const { data, isLoading, isError, isRefetching, isRefetchError, refetch } =
        useQuery(`getLiveUrl`, getLiveUrl, { enabled: false, });

    useEffect(() => {
        //console.log('useEffect choose_channelindex')
        if (Channels.length > 0 && online)
            refetch();
    }, [choose_channelindex])

    const VideoView = () => {
        //console.log('VideoView', data, isLoading, isError, isRefetching, isRefetchError)
        if (isLoading || isRefetching)
            return <div className='msg'>视频获取中...</div>
        if (isError || isRefetchError)
            return <div className='msg red'>视频获取失败</div>
        if (!data)
            return null
        else {
            if (data.data.Code === 0)
                return <div className='msg red'>视频地址获取失败</div>
            let { token, deviceSerial, channelNo } = data.data.Result;
            //console.log('url', url)
            return (
                <>
                    <VView2 token={token} deviceSerial={deviceSerial} channelNo={channelNo} showBigIframe={showBigIframe} />
                    {!showBigIframe ?
                        <div className='iconDiv' onClick={() => { SetShowBigIframe() }}>
                            <svg t="1653634908856" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5709" ><path d="M958.6 645.5c0-24.7-20.1-44.7-44.8-44.7-24.7 0-44.7 20-44.7 44.7v106.4c0 19.9-24.1 29.9-38.2 15.8L695.6 632.3c-17.5-17.5-45.8-17.5-63.3 0s-17.5 45.8 0 63.3L767.6 831c14.1 14.1 4.1 38.2-15.8 38.2H645.4c-24.7 0-44.7 20-44.7 44.7 0 24.7 20 44.7 44.7 44.7h223.7c49.4 0 89.6-40.1 89.6-89.5V645.5zM153.3 63.9c-49.4 0-89.4 40.1-89.4 89.5V377c0 24.7 20 44.7 44.7 44.7 24.7 0 44.8-20 44.8-44.7V270.6c0-19.9 24.1-29.9 38.1-15.8l135.3 135.3c17.5 17.5 45.8 17.5 63.3 0s17.5-45.8 0-63.3L254.7 191.5c-14.1-14.1-4.1-38.2 15.8-38.2h106.4c24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7H153.3z" p-id="5710"></path></svg>
                        </div>
                        :
                        null
                        // <div className='iconDiv2' onClick={() => { FullScreen() }}>
                        //   <svg t="1653634908856" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5709" ><path d="M958.6 645.5c0-24.7-20.1-44.7-44.8-44.7-24.7 0-44.7 20-44.7 44.7v106.4c0 19.9-24.1 29.9-38.2 15.8L695.6 632.3c-17.5-17.5-45.8-17.5-63.3 0s-17.5 45.8 0 63.3L767.6 831c14.1 14.1 4.1 38.2-15.8 38.2H645.4c-24.7 0-44.7 20-44.7 44.7 0 24.7 20 44.7 44.7 44.7h223.7c49.4 0 89.6-40.1 89.6-89.5V645.5zM153.3 63.9c-49.4 0-89.4 40.1-89.4 89.5V377c0 24.7 20 44.7 44.7 44.7 24.7 0 44.8-20 44.8-44.7V270.6c0-19.9 24.1-29.9 38.1-15.8l135.3 135.3c17.5 17.5 45.8 17.5 63.3 0s17.5-45.8 0-63.3L254.7 191.5c-14.1-14.1-4.1-38.2 15.8-38.2h106.4c24.7 0 44.7-20 44.7-44.7 0-24.7-20-44.7-44.7-44.7H153.3z" p-id="5710"></path></svg>
                        // </div>
                    }
                </>
            )
        }
    }

    var sc_height = document.documentElement.clientHeight - 100;
    var sc_width = sc_height / (700 / 1200);
    if ((sc_width + 100) > document.documentElement.clientWidth) {
        sc_width = document.documentElement.clientWidth - 100;
        sc_height = sc_width * (700 / 1200);
    }

    return (
        <>
            <div key={key} className='Right'>
                <div className='content_title'>
                    <div>设备详情({DeviceName})</div>
                    <div onClick={closeDetail}>
                        <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                    </div>
                </div>

                <div className='d_view'>
                    {
                        Channels.length > 1 &&
                        <>
                            <div className='d_channel'>
                                {
                                    Channels.map(({ ChannelNo }, index) => {
                                        let chooseChannel = choose_channelindex === index
                                        return (
                                            <div onClick={() => { changeChannel(index) }} className={chooseChannel ? 'd_channel_item choose' : 'd_channel_item'}>{ChannelNo}</div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </>
                    }
                    {!showBigIframe ?
                        <div className='iframe_p'>
                            {online ?
                                <VideoView />
                                :
                                <div className='offline'>设备离线(请检查硬件)</div>
                            }
                        </div>
                        :
                        <div className='iframe_p_dis'>查看大屏</div>
                    }

                    <div className='detail'>
                        <table>
                            <tr>
                                <td className='t'>名称</td>
                                <td colSpan={3}>{DeviceName}</td>
                            </tr>
                            <tr>
                                <td className='t'>设备ID</td>
                                <td>{id}</td>
                                <td className='t'>通道号</td>
                                <td>
                                    {choose_channelindex >= 0 ? Channels[choose_channelindex].ChannelNo : ''}
                                </td>
                            </tr>
                            <tr>
                                <td className='t'>状态</td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                        <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                    </div>
                                </td>
                                <td className='t'>地理位置</td>
                                <td>{Location}</td>
                            </tr>
                            <tr>
                                <td className='t'>设备类型</td>
                                <td>{DeviceType}</td>
                                <td className='t'>摄像头序列号</td>
                                <td>{DeviceSerial}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            {
                showBigIframe &&
                <div className='BigIframeBj'>
                    <div className='BigIframe' style={{ width: sc_width, height: sc_height }}>
                        <div className='Content'>
                            <div className='Content2'>
                                {/* <iframe
              src="https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/J62969020/1.hd.live&autoplay=1&accessToken=at.2ho0w6btbh339e161asyzilh6od12rqd-1ao11kuqnt-0ntr0af-m3euykiux"
            > */}
                                {/* </iframe> */}
                                <VideoView />
                                <div className='close' onClick={() => { SetShowBigIframe(false) }}>
                                    <svg t="1653642883237" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12581"  ><path d="M512 960C264.96 960 64 759.04 64 512S264.96 64 512 64s448 200.96 448 448S759.04 960 512 960zM512 128.288C300.416 128.288 128.288 300.416 128.288 512c0 211.552 172.128 383.712 383.712 383.712 211.552 0 383.712-172.16 383.712-383.712C895.712 300.416 723.552 128.288 512 128.288z" p-id="12582"></path><path d="M557.056 513.376l138.368-136.864c12.576-12.416 12.672-32.672 0.256-45.248-12.416-12.576-32.704-12.672-45.248-0.256l-138.56 137.024-136.448-136.864c-12.512-12.512-32.736-12.576-45.248-0.064-12.512 12.48-12.544 32.736-0.064 45.248l136.256 136.672-137.376 135.904c-12.576 12.448-12.672 32.672-0.256 45.248 6.272 6.336 14.496 9.504 22.752 9.504 8.128 0 16.256-3.104 22.496-9.248l137.568-136.064 138.688 139.136c6.24 6.272 14.432 9.408 22.656 9.408 8.192 0 16.352-3.136 22.592-9.344 12.512-12.48 12.544-32.704 0.064-45.248L557.056 513.376z" p-id="12583"></path></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

// const VView = ({ url, controls }) => {
//     console.log('VView')
//     return (
//         <ReactPlayer
//             config={{ attributes: { poster: '/loading.gif' } }}
//             width='100%'
//             style={{ overflow: 'hidden' }}
//             controls={controls}
//             height='auto'
//             playing={true}
//             muted={true}
//             url={url}
//             onError={(error, data) => {
//                 console.log('ReactPlayer onError', error, data);
//             }}
//             onEnded={() => {
//                 console.log('ReactPlayer onEnded');
//             }}
//             onProgress={({ played, playedSeconds, loaded, loadedSeconds }) => {
//                 console.log('ReactPlayer onProgress', played, playedSeconds, loaded, loadedSeconds);
//             }}
//         />
//     )
// }
const VView2 = ({ token, deviceSerial, channelNo, showBigIframe }) => {
    //console.log('VView2')
    //const player = null;
    useEffect(() => {
    }, [])
    let width = 378; let height = 214;
    if (showBigIframe) {
        //width = 1200; height = 700;
        var sc_height = document.documentElement.clientHeight - 100;
        var sc_width = sc_height / (700 / 1200);
        if ((sc_width + 100) > document.documentElement.clientWidth) {
            sc_width = document.documentElement.clientWidth - 100;
            sc_height = sc_width * (700 / 1200);
        }
        sc_width-=2;
        sc_height-=2;

        width = sc_width; height = sc_height;
    }
    return (
        <iframe src={`/palyVideo.html?accessToken=${token.accessToken}&deviceSerial=${deviceSerial}&channelNo=${channelNo}&width=${width}&height=${height}`} style={{ border: 'none' }}></iframe>
    )
}


// class VView2 extends React.Component {
//     constructor(props) {
//         super(props);
//         this.playr = null; //定义播放器
//     }
//     componentDidMount() {
//         console.log('VView2', this.props)
//         let { token, deviceSerial, channelNo } = this.props;
//         if (true) {
//             //window.global.playr = new EZUIKit.EZUIKitPlayer({
//             try { this.playr.stop(); } catch { }
//             this.playr = new EZUIKit.EZUIKitPlayer({
//                 id: 'video-container', // 视频容器ID
//                 accessToken: token.accessToken,
//                 url: `ezopen://open.ys7.com/${deviceSerial}/${channelNo}.live`,
//                 template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
//                 plugin: ['talk'],                       // 加载插件，talk-对讲
//                 width: 378,
//                 height: 214,
//             });
//         }
//         else {
//             // window.global.playr.changePlayUrl({ accessToken: token.accessToken, deviceSerial: deviceSerial, channelNo: channelNo })
//             //     .then(() => {
//             //         console.log("切换成功")
//             //     });
//         }
//     }
//     componentDidUpdate() {
//         let { token, deviceSerial, channelNo } = this.props;
//         // this.player.stop();
//         // this.player.accessToken = token.accessToken;
//         // this.player.url = `ezopen://open.ys7.com/${deviceSerial}/${channelNo}.live`;
//         // this.player.start();
//         // window.global.playr.changePlayUrl({ accessToken: token.accessToken, deviceSerial: deviceSerial, channelNo: channelNo })
//         //     .then(() => {
//         //         console.log("切换成功")
//         //     });
//     }
//     render() {
//         return (
//             <div>
//                 <div id="video-container"
//                     style={{ width: 378, height: 214 }}
//                 >
//                 </div>
//             </div>
//         )
//     }
// }


