//window.global._AMap;
//window.global.map;

export const SetMapChoose = (key) => {
    try {
        var list = document.querySelectorAll('.mapDian');
        list.forEach(function (item) {
            item.classList.remove("mapDian");
        });
        if (key.length > 0) {
            var nowItem = document.querySelector('.' + key);
            if (nowItem != null) {
                var timer = 200;
                try {
                    let positon = eval(`new window.global._AMap.LngLat(${nowItem.getAttribute("Location")})`);
                    window.global.map.setZoomAndCenter(12, positon, false, timer);
                } catch (e) { }
                setTimeout(() => {
                    nowItem.classList.add("mapDian");
                }, timer)
            }
        }
    }
    catch (e2) {

    }
}