
import React, { useState, useEffect } from 'react';

const Timer = () => {
    let [nowTime, setNowTime] = useState('');
    useEffect(() => {
        let interval = setInterval(() => { getTime(); }, 1000);
        return () => clearInterval(interval);
    }, [])
    const getTime = () => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        var timeString = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0') + ' ' + hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0') + ':' + second.toString().padStart(2, '0');
        var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
        var week = weeks[date.getDay()];
        setNowTime(timeString + ' ' + week);
    }
    return nowTime;
}

export default Timer;
