import React, { useState, useEffect } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import request from './custom/request';
import ReactPlayer from 'react-player'
import { SetMapChoose } from './custom/common';

export default ({
    ckey, choose, setChoose, chooseDeviceIndex, setChooseDeviceIndex,
    data, isLoading, isError, isRefetching, isRefetchError, refetch
}) => {
    let key = ckey;

    const isChoose = key === choose;

    const clickMemu = () => {
        //console.log('key', key, choose, choose)
        const { Code, Message, Result } = data.data;
        if (!isLoading && !isError && Code == 1 && !isChoose) {
            setChoose(key);
        }
    }

    const closeMemu = () => {
        setChooseDeviceIndex(-1)
        setChoose('');
        SetMapChoose(``);
    }
    const refreshData = () => {
        setChooseDeviceIndex(-1)
        refetch();
    }

    //console.log(isLoading, isError, isRefetching, isRefetchError)
    const getData = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            return Result.filter(t => t.DevType === 6)
        }
        return [];
    }

    const getOnlineCount = () => {
        let onlineCount = 0;
        getData().forEach(item => {
            if (item.Status === 1) {
                onlineCount++;
            }
        })
        return onlineCount;
    }

    const getOnlineBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            //console.log('getOnlineCount() / Result.length', getOnlineCount() / Result.length)
            return parseInt(getOnlineCount() / getData().length * 100) + "%"
        }
    }
    const getOnlineCountBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            return `${getOnlineCount()}/${getData().length}`;
        }
    }

    const DataList = () => {
        return (
            <>
                {getData().map((item, index) => {
                    let { id, DeviceId, DeviceName, Title, DisplayNo, Status } = item;
                    const online = Status === 1;
                    const chooseItem = index === chooseDeviceIndex;
                    return (
                        <div className={chooseItem ? 'table_tr active' : 'table_tr'} onClick={() => {
                            setChooseDeviceIndex(index);
                            SetMapChoose(`mapDian_Meteorological_tr_${index}`);
                        }}>
                            <div className='table_td first'>{DeviceId}</div>
                            <div className='table_td f'>{`${DeviceName || Title}`}</div>
                            <div className='table_td r'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                    <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )

    }

    return (
        <div onClick={clickMemu} className={isChoose ? 'menu active' : 'menu'}>
            <div className='iconDiv'>
                <svg t="1653530607921" className="icon" viewBox="0 0 1083 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1188" width="200" height="200"><path d="M1081.123967 479.485884l-161.741223-85.432066 161.555041-105.446611L540.570446 0.69395 0.186182 288.598744l161.555041 105.446611L0 479.494347l161.741223 105.573554L0 670.491504l540.561983 352.814546 540.570447-352.814546-161.749686-85.432066 161.749686-105.573554zM956.162116 294.167273l-107.223802 69.987438-93.810248-58.42724 100.241984-65.248264 100.792066 53.688066zM482.320926 407.484298l-91.982281 59.882842-104.752662-68.379504 94.648067-57.953322 102.086876 66.449984z m-42.060166-103.21243l96.966876-59.383537 99.581885 62.023933-96.247538 62.658645-100.301223-65.299041zM540.561983 445.397686l92.049984 59.925157-92.049984 60.08595-92.049983-60.08595 92.049983-59.925157z m58.249521-37.921851l97.533884-63.496463 93.886414 58.478016-99.438017 64.892827-91.990744-59.87438z m192.495075-201.152529l-95.714381 62.311669-97.745454-60.881454 91.246016-55.888397 102.213819 54.458182zM540.561983 72.737851l83.451769 44.455141-86.506843 52.97719-82.910149-51.631603 85.965223-45.800728zM389.797025 153.066843l87.082314 54.238149-95.612827 58.562644-91.440661-59.535867 99.971174-53.264926zM124.970314 294.15881l100.792066-53.696529 95.477422 62.159339-94.571901 57.911008-101.697587-66.373818z m0.186182 191.132562l97.483107-51.496198 317.92238 207.508099 317.930843-207.508099 97.474645 51.496198L540.561983 756.40595 125.164959 485.291372z m830.810975 191.00562L540.561983 947.41157 125.164959 676.288529l97.483107-51.487736 317.92238 207.5081 317.930843-207.5081 97.474645 51.487736z" p-id="1189"></path></svg>
            </div>
            {!isLoading ?
                (
                    !isError ?
                        <>
                            <div className='count'>
                                {getOnlineCountBili()}
                            </div>
                            <div className='line'>
                                <div className='line_right' time='6' style={{ width: getOnlineBili() }}>
                                </div>
                            </div>
                        </>
                        :
                        <div className='err'>Err</div>
                )
                : <div className='loading'>Loading</div>
            }
            {!isLoading && !isError &&
                <>
                    <div className='text'>土壤墒情</div>
                    <div className='content'>
                        <div className='content_title'>
                            <div>设备列表</div>
                            <div onClick={closeMemu}>
                                <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                            </div>
                        </div>
                        <div className='content_cont'>
                            <div className='table_tr t'>
                                <div className='table_th'>设备ID</div>
                                <div className='table_th f'>设备名称</div>
                                <div className='table_th r'>
                                    <div className='status' onClick={refreshData}>
                                        {isRefetching ?
                                            <span>Refresh</span>
                                            :
                                            <>
                                                <svg style={{ marginRight: 2 }} t="1655482056814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2922" width="18" height="18"><path d="M935.161672 427.51891c-14.511505-11.744485-37.643342-9.155521-49.1627 5.403057l-12.9438 16.20917c-0.926092-5.842055-1.995447-11.625782-3.158946-17.325597C831.326792 245.594511 666.360623 110.434182 477.668077 110.434182c-27.455305 0-55.099922 2.885723-82.198094 8.562003C179.036629 164.405397 39.60195 378.546545 84.655052 596.34499c38.522362 186.222285 203.488531 321.383638 392.229173 321.383638 27.430746 0 55.076386-2.873444 82.174558-8.549723 75.144444-15.746636 144.18589-53.508681 198.288089-108.002806l1.87572-1.662873c1.757017-1.74576 2.778276-3.432169 2.588965-3.443425l1.781576-2.387373c2.137687-3.527336 4.65502-9.191336 4.65502-16.173354 0-17.361413-14.035668-31.479969-31.326473-31.479969-4.275373 0-8.454556 0.914836-12.325723 2.612501l-1.90028-1.318018-8.644891 8.65717c-46.359864 46.478568-104.261599 78.042447-167.484525 91.283006-22.657023 4.750187-45.766346 7.160073-68.684312 7.160073-157.818375 0-295.733445-113.073288-327.96145-268.87268-37.738509-182.291766 78.849836-361.484961 259.918751-399.448598 22.657023-4.750187 45.766346-7.160073 68.708871-7.160073 157.793816 0 295.709909 113.061009 327.96145 268.860401 0.427742 2.101871 0.855484 4.227278 1.258667 6.364965l-13.751189-11.091616c-14.511505-11.768021-37.59627-9.1678-49.1627 5.390777-12.017708 15.056927-9.619078 37.156248 5.343705 49.269124l78.089519 63.1032c0.14224 0.106424 0.285502 0.213871 0.427742 0.332575l3.491521 2.814092 0.712221 0c6.483668 3.657296 15.770172 4.964058 21.065781 4.322445 9.475815-0.890276 17.954931-5.485945 23.940249-12.93152l62.723553-78.659501C952.498526 461.635939 950.052824 439.560154 935.161672 427.51891z" p-id="2923" fill='#FFF'></path></svg>
                                                <span>状态</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <DataList />
                        </div>
                    </div>
                    {isChoose && chooseDeviceIndex >= 0 &&
                        <Right key={'Meteorological-qx-right' + chooseDeviceIndex} cindex={chooseDeviceIndex} cdata={getData()[chooseDeviceIndex]} closeDetail={() => {
                            setChooseDeviceIndex(-1);
                            SetMapChoose(``);
                        }} />
                    }
                </>
            }
        </div>
    )
}

const Right = ({ key, cdata, closeDetail }) => {
    //console.log('Right')
    let { id, DeviceId, DeviceName, Title, DisplayNo, Status, Location } = cdata;
    const online = Status === 1;

    const { data, isLoading, isError, isRefetching, isRefetchError, refetch } =
        useQuery(`MeteorologicalQXDetail`, () => request.get('/Meteorological/GetQXDetail', { params: { devId: DeviceId } }), { enabled: false, });

    useEffect(() => {
        refetch();
    }, [])

    const DataView = () => {
        //console.log('VideoView', data, isLoading, isError, isRefetching, isRefetchError)
        if (isLoading || isRefetching)
            return <div className='msg'>数据获取中...</div>
        if (isError || isRefetchError)
            return <div className='msg red'>数据获取失败</div>
        if (!data)
            return null
        else {
            if (data.data.Code === 0)
                return <div className='msg red'>数据信息获取失败</div>
            let list = data.data.Result;
            return (
                <>
                    <div className='Meteorological_tr'>
                        <View1 bgcolor='rgb(62, 185, 131)' title={'土壤温度'} list={list} svg={<svg t="1656315130569" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="959" width="50" height="50"><path d="M737.957791 396.958354V96.140463A97.726849 97.726849 0 0 0 635.243288 0.12812h-260.293201a97.726849 97.726849 0 0 0-102.558639 96.012343v313.598755A339.939804 339.939804 0 0 0 145.67386 675.331802c0 191.868821 164.280859 348.668198 366.280852 348.668198S878.235564 867.200622 878.235564 672.058654a346.797828 346.797828 0 0 0-140.277773-275.1003zM511.954712 960.095681c-163.657402 0-297.856472-128.120366-297.856472-288.037027a281.80246 281.80246 0 0 1 113.00154-223.976845l13.716048-9.66358V343.029344h129.990737a33.354937 33.354937 0 0 0 34.290122-32.108024 31.172838 31.172838 0 0 0-34.290122-31.952159h-129.990737v-58.293208h129.990737a33.354937 33.354937 0 0 0 34.290122-31.95216 31.172838 31.172838 0 0 0-34.290122-31.172838h-129.990737V96.140463a31.172838 31.172838 0 0 1 34.134259-31.172839h260.293201a31.172838 31.172838 0 0 1 34.134258 31.172839v335.887334l13.716049 9.66358a285.387336 285.387336 0 0 1 126.717588 233.796289c0 156.487649-134.19907 284.608015-297.856471 284.608015z" p-id="960" fill="#ffffff"></path><path d="M724.241742 633.716062a31.172838 31.172838 0 0 0-37.719135 28.679012 193.583327 193.583327 0 0 1-133.419749 166.462957 32.887345 32.887345 0 0 0 10.131173 63.904319 28.055555 28.055555 0 0 0 13.716049-3.117284A247.97993 247.97993 0 0 0 754.946988 668.785506a29.458332 29.458332 0 0 0-30.705246-35.069444z" p-id="961" fill="#ffffff"></path></svg>} />
                        <View1 bgcolor='rgb(96, 133, 245)' title={'土壤湿度'} list={list} svg={<svg t="1656314966977" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2761" width="50" height="50"><path d="M798.72 354.986667l-259.413333-310.613334c-13.653333-17.066667-40.96-17.066667-51.2 0l-259.413334 310.613334c-119.466667 143.36-119.466667 354.986667 0 498.346666 68.266667 88.746667 174.08 136.533333 283.306667 136.533334 109.226667 0 215.04-47.786667 286.72-133.12 119.466667-146.773333 119.466667-354.986667 0-501.76z m-54.613333 457.386666c-58.026667 68.266667-143.36 109.226667-232.106667 109.226667s-174.08-40.96-232.106667-109.226667c-98.986667-119.466667-98.986667-293.546667 0-413.013333L512 122.88l232.106667 279.893333c102.4 116.053333 102.4 290.133333 0 409.6z m-259.413334-276.48c0-71.68-27.306667-92.16-71.68-92.16s-71.68 20.48-71.68 92.16 27.306667 92.16 71.68 92.16c44.373333-3.413333 71.68-20.48 71.68-92.16z m-98.986666 0c0-44.373333 6.826667-54.613333 27.306666-54.613333 20.48 0 27.306667 10.24 27.306667 54.613333s-6.826667 54.613333-27.306667 54.613334c-20.48 0-27.306667-10.24-27.306666-54.613334z m197.973333-88.746666l-187.733333 331.093333h44.373333l187.733333-331.093333h-44.373333z m27.306667 157.013333c-44.373333 0-71.68 20.48-71.68 92.16s27.306667 92.16 71.68 92.16 71.68-20.48 71.68-92.16-27.306667-92.16-71.68-92.16z m0 146.773333c-20.48 0-27.306667-10.24-27.306667-54.613333s6.826667-54.613333 27.306667-54.613333c20.48 0 27.306667 10.24 27.306666 54.613333 0 40.96-6.826667 54.613333-27.306666 54.613333z" p-id="2762" fill="#ffffff"></path></svg>} />
                    </div>
                </>
            )
        }
    }
    const View1 = ({ list, title, svg, bgcolor = '#000' }) => {
        var n = list.find(t => t.DataName === title);
        return (
            <div className='View2' style={{ backgroundColor: bgcolor }}>
                <div>
                    {svg}
                </div>
                <div className='Item2'>
                    {title}
                </div>
                <div className='Item'>
                    {n != null ? `${n.DataValue}${n.Unit}` : '--'}
                </div>
            </div>
        )
    }

    return (
        <>
            <div key={key} className='Right'>
                <div className='content_title'>
                    <div>设备详情</div>
                    <div onClick={closeDetail}>
                        <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                    </div>
                </div>

                <div className='d_view'>

                    <div className='iframe_p'>
                        {online ?
                            <DataView />
                            :
                            <div className='offline'>设备离线(请检查硬件)</div>
                        }
                    </div>
                    <div className='detail'>
                        <table>
                            <tr>
                                <td className='t'>名称</td>
                                <td colSpan={3}>{DeviceName || Title}</td>
                            </tr>
                            <tr>
                                <td className='t'>设备ID</td>
                                <td>{DeviceId}</td>
                                <td className='t'>序列号</td>
                                <td>
                                    {DisplayNo}
                                </td>
                            </tr>
                            <tr>
                                <td className='t'>状态</td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                        <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                    </div>
                                </td>
                                <td className='t'>地理位置</td>
                                <td>{Location}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
