import React, { useState, useEffect } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import request from './custom/request';
import { SetMapChoose } from './custom/common';

export default (props) => {

    let { ckey, choose, setChoose, chooseDeviceIndex, setChooseDeviceIndex } = props;
    let key = ckey;
    let [circleMarkers, setCircleMarkers] = useState([]);

    //console.log('Memu', props)
    const isChoose = key === choose;

    let svgIcon = '<svg t="1655818074737" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4133" width="40" height="40" style="fill:#0acefc"><path d="M512.434393 66.861804c-0.054235 0-0.109494 0-0.163729 0l-0.541329 0c-0.056282 0-0.109494 0-0.163729 0-191.363376 0.434905-346.930477 156.1115-346.930477 347.637582 0 188.810227 119.553886 338.510714 326.018192 532.535709 0.325411 0.434905 0.434905 0.977257 0.814552 1.302669 3.042289 3.260254 6.735401 5.432733 10.646478 6.843872 0.923022 0.325411 1.954515 0.325411 2.933819 0.543376 2.226714 0.542352 4.399193 1.412163 6.681166 1.412163 0.107447 0 0.161682-0.10847 0.270153-0.10847 0.109494 0 0.163729 0.10847 0.272199 0.10847 2.281973 0 4.455475-0.86981 6.681166-1.412163 0.977257-0.217964 2.010797-0.217964 2.933819-0.543376 3.90903-1.412163 7.605212-3.584642 10.646478-6.843872 0.379647-0.325411 0.488117-0.868787 0.813528-1.302669 206.464305-194.024995 326.019215-343.725482 326.019215-532.535709C859.364871 222.973304 703.796746 67.296709 512.434393 66.861804zM511.998465 596.247776c-101.193727 0-183.541223-82.347496-183.541223-183.596482 0-101.247962 82.347496-183.486988 183.541223-183.486988 101.195773 0 183.541223 82.238003 183.541223 183.486988C695.539688 513.901303 613.194238 596.247776 511.998465 596.247776z" p-id="4134"></path></svg>';
    let svgIcon2 = '<div style="position: absolute;top: 8px;left: 9px;height: 17px;width: 20px;background: #0acefc;"></div>';
    // let svgIcon3 = '<svg t="1655818744346" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5704" width="20" height="20" style="position: absolute;top: 2px;left: 5px;fill: #FFF;"><path d="M98.461538 546.953846c0-12.228923 9.176615-18.215385 20.401231-13.272615l86.390154 37.927384c11.224615 4.923077 20.401231 18.944 20.401231 31.172923v49.368616h144.738461l27.963077-60.435692 85.543385 39.778461-40.448 87.433846a47.241846 47.241846 0 0 1-42.870154 27.313231h-174.966154v49.368615c0 12.248615-9.156923 26.249846-20.401231 31.172923l-86.350769 37.96677C107.638154 869.651692 98.461538 863.704615 98.461538 851.456z m814.67077 3.328l-58.978462 126.070154-84.381538-39.227077 143.36-86.843077zM361.491692 161.673846L947.593846 434.136615c22.193231 10.358154 23.236923 29.184 2.284308 41.865847l-262.833231 159.232c-20.952615 12.681846-56.280615 14.611692-78.513231 4.292923l-386.500923-179.672616a44.563692 44.563692 0 0 1-21.582769-59.076923l101.769846-217.6a44.780308 44.780308 0 0 1 59.273846-21.504z" p-id="5705"></path></svg>';
    let svgIcon3 = '<svg t="1656311890572" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4651" width="25" height="25" style="position: absolute;top: 6px;left: 7px;fill: #FFF;"><path d="M768 597.333333a192 192 0 0 0 0-384H526.933333l-184.32 395.52L158.08 213.333333H64l278.613333 597.333334 238.72-512H768a106.666667 106.666667 0 0 1 0 213.333333H540.586667l298.666666 298.666667H960L746.666667 597.333333z" p-id="4652"></path></svg>';
    const aClick = (url, id) => {
        let a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.setAttribute("id", id);
        // 防止反复添加      
        if (!document.getElementById(id)) {
            document.body.appendChild(a);
        }
        a.click();
    }

    const { data, isLoading, isError, isRefetching, isRefetchError, refetch } =
        useQuery(`VRList`, () => request.get('/VR/GetList', { params: {} }), {
            onSuccess: (data) => {
                try {
                    circleMarkers.forEach((item) => {
                        window.global.map.remove(item);
                    })
                    let circleMarkerArr = [];
                    data.data.Result.map((item, index) => {
                        let { Location, VRName, VRUrl, id } = item;
                        if (Location.length > 0) {
                            // var circleMarker = new window.global._AMap.CircleMarker({
                            //     center: eval(`new window.global._AMap.LngLat(${Location})`),
                            //     radius: 10 + Math.random() * 1,//3D视图下，CircleMarker半径不要超过64px
                            //     strokeColor: 'white',
                            //     strokeWeight: 2,
                            //     strokeOpacity: 0.5,
                            //     fillColor: 'rgba(255,0,0)',
                            //     fillOpacity: Status && Status.Status === 1 ? 0.8 : 0.2,
                            //     zIndex: 9999,
                            //     bubble: true,
                            //     cursor: 'pointer',
                            //     clickable: true
                            // })
                            var circleMarker = new window.global._AMap.Marker({
                                content: `<div href='http://baidu.com' target='_blank' Location='${Location}' class='mapDian_ mapDian_VR_${index}' style="position:relative;height:40px;overflow:hidden;">${svgIcon}${svgIcon2}${svgIcon3}</div>`,  // 自定义点标记覆盖物内容
                                position: eval(`new window.global._AMap.LngLat(${Location})`),
                                anchor: 'bottom-center',
                                title: VRName,
                                //animation:'AMAP_ANIMATION_BOUNCE',
                                //offset: new window.global._AMap.Pixel(-15, -30) // 相对于基点的偏移位置
                            });

                            circleMarker.on('click', () => {
                                //TODO:j
                                aClick(VRUrl,`mapDian_VR_${index}`)
                            })

                            //circleMarker.setAnimation('AMAP_ANIMATION_BOUNCE')
                            circleMarkerArr.push(circleMarker);
                        }
                    })
                    setCircleMarkers(circleMarkerArr);
                    window.global.map.add(circleMarkerArr);
                    //return true;
                } catch { }
            }, enabled: false
        });


    useEffect(() => {
        refetch();
    }, [])

    const DataList = () => {
    }

    return null;
}