import React, { useState, useEffect } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import request from './custom/request';
import ReactPlayer from 'react-player'
import { SetMapChoose } from './custom/common';
import congData from './custom/cong.json';

export default ({
    ckey, choose, setChoose, chooseDeviceIndex, setChooseDeviceIndex,
    data, isLoading, isError, isRefetching, isRefetchError, refetch
}) => {
    let key = ckey;

    const isChoose = key === choose;

    const clickMemu = () => {
        //console.log('key', key, choose, choose)
        const { Code, Message, Result } = data.data;
        if (!isLoading && !isError && Code == 1 && !isChoose) {
            setChoose(key);
        }
    }

    const closeMemu = () => {
        setChooseDeviceIndex(-1)
        setChoose('');
        SetMapChoose(``);
    }
    const refreshData = () => {
        setChooseDeviceIndex(-1)
        refetch();
    }

    //console.log(isLoading, isError, isRefetching, isRefetchError)
    const getData = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            return Result.filter(t => t.DevType === 23)
        }
        return [];
    }

    const getOnlineCount = () => {
        let onlineCount = 0;
        getData().forEach(item => {
            if (item.Status === 1) {
                onlineCount++;
            }
        })
        return onlineCount;
    }

    const getOnlineBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            //console.log('getOnlineCount() / Result.length', getOnlineCount() / Result.length)
            return parseInt(getOnlineCount() / getData().length * 100) + "%"
        }
    }
    const getOnlineCountBili = () => {
        if (data) {
            const { Code, Message, Result } = data.data;
            return `${getOnlineCount()}/${getData().length}`;
        }
    }

    const DataList = () => {
        return (
            <>
                {getData().map((item, index) => {
                    let { id, DeviceId, DeviceName, Title, DisplayNo, Status } = item;
                    const online = Status === 1;
                    const chooseItem = index === chooseDeviceIndex;
                    return (
                        <div className={chooseItem ? 'table_tr active' : 'table_tr'} onClick={() => {
                            setChooseDeviceIndex(index);
                            SetMapChoose(`mapDian_Meteorological_cq_${index}`);
                        }}>
                            <div className='table_td first'>{DeviceId}</div>
                            <div className='table_td f'>{`${DeviceName || Title}`}</div>
                            <div className='table_td r'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                    <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )

    }

    //console.log("111222", getData(), chooseDeviceIndex);

    return (
        <div style={{ zIndex: 10 }} onClick={clickMemu} className={isChoose ? 'menu active' : 'menu'}>
            <div className='iconDiv'>
                <svg t="1653531013384" className="icon" viewBox="0 0 1092 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1883" width="200" height="200"><path d="M994.133333 832c25.506133 0 49.809067-9.813333 68.437334-27.613867 19.1488-18.304 29.696-42.5984 29.696-68.386133V640h-98.133334c-24.576 0-47.0016 9.2928-64 24.5248A95.607467 95.607467 0 0 0 866.133333 640H770.133333v96c0 52.932267 43.067733 96 96 96h32v128h-320v-128h32c52.932267 0 96-43.067733 96-96V640H610.133333c-24.576 0-47.0016 9.2928-64 24.5248A95.607467 95.607467 0 0 0 482.133333 640H386.133333v96c0 52.932267 43.067733 96 96 96h32v128h-320v-128h32c52.932267 0 96-43.067733 96-96V640H226.133333c-24.576 0-47.0016 9.2928-64 24.5248A95.607467 95.607467 0 0 0 98.133333 640H0v96c0 25.787733 10.5472 50.082133 29.696 68.386133C48.3328 822.1952 72.635733 832 98.133333 832h32v128H0v64h1092.266667v-64h-130.133334v-128h32z m-32-96a32.034133 32.034133 0 0 1 32-32h34.133334v32c0 17.0496-15.957333 32-34.133334 32h-32v-32z m-384 0a32.034133 32.034133 0 0 1 32-32h32v32A32.034133 32.034133 0 0 1 610.133333 768h-32v-32z m-384 0a32.034133 32.034133 0 0 1 32-32h32v32A32.034133 32.034133 0 0 1 226.133333 768h-32v-32zM130.133333 768H98.133333c-18.176 0-34.133333-14.9504-34.133333-32V704h34.133333a32.034133 32.034133 0 0 1 32 32V768z m384 0H482.133333a32.034133 32.034133 0 0 1-32-32V704h32a32.034133 32.034133 0 0 1 32 32V768z m384 0h-32a32.034133 32.034133 0 0 1-32-32V704h32a32.034133 32.034133 0 0 1 32 32V768zM1092.266667 64H770.133333v67.217067c72.942933 14.8736 128 79.5136 128 156.782933v32h-704v-32c0-77.269333 55.057067-141.909333 128-156.782933V64H0V0h1092.266667v64z m-706.133334 0V128h320V64h-320zM828.6464 256c-13.201067-37.248-48.7936-64-90.513067-64h-384c-41.719467 0-77.312 26.752-90.513066 64h565.026133zM322.133333 384h64v128h-64V384z m128 0h64v128h-64V384z m128 0h64v128h-64V384z m128 0h64v128h-64V384z" p-id="1884"></path></svg>
            </div>
            {!isLoading ?
                (
                    !isError ?
                        <>
                            <div className='count'>
                                {getOnlineCountBili()}
                            </div>
                            <div className='line'>
                                <div className='line_right' time='6' style={{ width: getOnlineBili() }}>
                                </div>
                            </div>
                        </>
                        :
                        <div className='err'>Err</div>
                )
                : <div className='loading'>Loading</div>
            }
            {!isLoading && !isError &&
                <>
                    <div className='text'>虫情测报灯</div>
                    <div className='content'>
                        <div className='content_title'>
                            <div>设备列表</div>
                            <div onClick={closeMemu}>
                                <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                            </div>
                        </div>
                        <div className='content_cont'>
                            <div className='table_tr t'>
                                <div className='table_th'>设备ID</div>
                                <div className='table_th f'>设备名称</div>
                                <div className='table_th r'>
                                    <div className='status' onClick={refreshData}>
                                        {isRefetching ?
                                            <span>Refresh</span>
                                            :
                                            <>
                                                <svg style={{ marginRight: 2 }} t="1655482056814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2922" width="18" height="18"><path d="M935.161672 427.51891c-14.511505-11.744485-37.643342-9.155521-49.1627 5.403057l-12.9438 16.20917c-0.926092-5.842055-1.995447-11.625782-3.158946-17.325597C831.326792 245.594511 666.360623 110.434182 477.668077 110.434182c-27.455305 0-55.099922 2.885723-82.198094 8.562003C179.036629 164.405397 39.60195 378.546545 84.655052 596.34499c38.522362 186.222285 203.488531 321.383638 392.229173 321.383638 27.430746 0 55.076386-2.873444 82.174558-8.549723 75.144444-15.746636 144.18589-53.508681 198.288089-108.002806l1.87572-1.662873c1.757017-1.74576 2.778276-3.432169 2.588965-3.443425l1.781576-2.387373c2.137687-3.527336 4.65502-9.191336 4.65502-16.173354 0-17.361413-14.035668-31.479969-31.326473-31.479969-4.275373 0-8.454556 0.914836-12.325723 2.612501l-1.90028-1.318018-8.644891 8.65717c-46.359864 46.478568-104.261599 78.042447-167.484525 91.283006-22.657023 4.750187-45.766346 7.160073-68.684312 7.160073-157.818375 0-295.733445-113.073288-327.96145-268.87268-37.738509-182.291766 78.849836-361.484961 259.918751-399.448598 22.657023-4.750187 45.766346-7.160073 68.708871-7.160073 157.793816 0 295.709909 113.061009 327.96145 268.860401 0.427742 2.101871 0.855484 4.227278 1.258667 6.364965l-13.751189-11.091616c-14.511505-11.768021-37.59627-9.1678-49.1627 5.390777-12.017708 15.056927-9.619078 37.156248 5.343705 49.269124l78.089519 63.1032c0.14224 0.106424 0.285502 0.213871 0.427742 0.332575l3.491521 2.814092 0.712221 0c6.483668 3.657296 15.770172 4.964058 21.065781 4.322445 9.475815-0.890276 17.954931-5.485945 23.940249-12.93152l62.723553-78.659501C952.498526 461.635939 950.052824 439.560154 935.161672 427.51891z" p-id="2923" fill='#FFF'></path></svg>
                                                <span>状态</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <DataList />
                        </div>
                    </div>
                    {isChoose && chooseDeviceIndex >= 0 &&
                        <Right key={'Meteorological-qx-right' + chooseDeviceIndex} cindex={chooseDeviceIndex} cdata={getData()[chooseDeviceIndex]} closeDetail={() => {
                            setChooseDeviceIndex(-1);
                            SetMapChoose(``);
                        }} />
                    }
                </>
            }
        </div>
    )
}

const Right = ({ key, cdata, closeDetail }) => {
    //console.log('Right')
    let { id, DeviceId, DeviceName, Title, DisplayNo, Status, Location } = cdata;
    const online = Status === 1;

    const { data, isLoading, isError, isRefetching, isRefetchError, refetch } =
        useQuery(`MeteorologicalCQDetail`, () => request.get('/Meteorological/GetCQDetail', { params: { devId: DeviceId } }), { enabled: false, });

    useEffect(() => {
        refetch();
    }, [])

    const DataView = () => {
        //console.log('VideoView', data, isLoading, isError, isRefetching, isRefetchError)
        if (isLoading || isRefetching)
            return <div className='msg'>数据获取中...</div>
        if (isError || isRefetchError)
            return <div className='msg red'>数据获取失败</div>
        if (!data)
            return null
        else {
            if (data.data.Code === 0)
                return <div className='msg red'>数据信息获取失败</div>
            let list = data.data.Result;
            return (
                // <table style={{ width: '100%' }}>
                //     <tr>
                //         <th >日期</th>
                //         <th >设备编号</th>
                //         <th >测得数量</th>
                //         <th >拍照</th>
                //     </tr>
                //     {list.length === 0 &&
                //         <td colSpan={4} style={{ textAlign: 'center', opacity: 0.5 }}>{'还没有任何数据'}</td>
                //     }
                //     {list.map(item => View1(item))}
                // </table>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {list.length === 0 &&
                        <div style={{ textAlign: 'center', opacity: 0.5 }}>{'还没有任何数据'}</div>
                    }
                    {list.map(item => View1(item))}
                </div>
            )
        }
    }
    const View1 = ({ UpTime, addr, indentify_result, indentify_photo, indentify_result_content }) => {
        const [showPic, setShowPic] = useState(false);
        const showBigPic = () => {
            setShowPic(true)
        }
        const congList = () => {
            let dataArr = [];
            indentify_result_content.split('#').forEach(itme => {
                var congs = itme.split(',')
                if (congs.length == 2) {
                    try {
                        let c_name = congData[congs[0]];
                        let c_value = congs[1];
                        dataArr.push({ name: c_name, value: c_value });
                    } catch { }
                }
            })
            return dataArr;
        }
        return (
            <>
                <div style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}>
                    <div onClick={showBigPic} style={{ position: 'relative' }}>
                        <img src={`${indentify_photo}?x-oss-process=image/resize,w_150,m_lfit`} width={'100%'} height={'auto'} />
                        <span style={{ opacity: 0.6, position: 'absolute', top: '5px', left: '5px', fontSize: '14px' }}>{new Date(UpTime).format('yy-MM-dd')}</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: '12px' }}>{`害虫总数：${indentify_result}`}</span>
                    </div>
                </div>
                {
                    showPic &&
                    <div style={{
                        position: 'fixed',
                        margin: 'auto',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        zIndex: 9999,
                        cursor: 'default'
                    }}>
                        <div style={{
                            position: 'fixed',
                            width: document.documentElement.clientHeight - 100,
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            border: '1px solid aqua',
                        }}>
                            <img src={`${indentify_photo}?x-oss-process=image/resize,w_2000,m_lfit`} width={'100%'} height={'auto'} />
                            <div class="close" onClick={() => setShowPic(false)} style={{ width: 40, height: 40, top: -40, right: -40, position: 'absolute' }}><svg t="1653642883237" class="icon" viewBox="0 0 1024 1024" version="1.1" fill='#FFFFFF' xmlns="http://www.w3.org/2000/svg" p-id="12581"><path d="M512 960C264.96 960 64 759.04 64 512S264.96 64 512 64s448 200.96 448 448S759.04 960 512 960zM512 128.288C300.416 128.288 128.288 300.416 128.288 512c0 211.552 172.128 383.712 383.712 383.712 211.552 0 383.712-172.16 383.712-383.712C895.712 300.416 723.552 128.288 512 128.288z" p-id="12582"></path><path d="M557.056 513.376l138.368-136.864c12.576-12.416 12.672-32.672 0.256-45.248-12.416-12.576-32.704-12.672-45.248-0.256l-138.56 137.024-136.448-136.864c-12.512-12.512-32.736-12.576-45.248-0.064-12.512 12.48-12.544 32.736-0.064 45.248l136.256 136.672-137.376 135.904c-12.576 12.448-12.672 32.672-0.256 45.248 6.272 6.336 14.496 9.504 22.752 9.504 8.128 0 16.256-3.104 22.496-9.248l137.568-136.064 138.688 139.136c6.24 6.272 14.432 9.408 22.656 9.408 8.192 0 16.352-3.136 22.592-9.344 12.512-12.48 12.544-32.704 0.064-45.248L557.056 513.376z" p-id="12583"></path></svg></div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: 14 }}>
                                {congList().map(item =>
                                    <div style={{
                                        margin: '0 5px 5px 0', padding: 10, color: '#FFF',
                                        backgroundColor: '#0e8eaca1'
                                    }}>
                                        {item.name}({item.value})
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <>
            <div key={key} className='Right cq'>
                <div className='content_title'>
                    <div>设备详情</div>
                    <div onClick={closeDetail}>
                        <svg t="1655481931162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2121" width="20" height="20"><path d="M571.733333 512l268.8-268.8c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667 243.2 183.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333L452.266667 512 183.466667 780.8c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8L512 571.733333l268.8 268.8c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z" p-id="2122" fill='#FFF'></path></svg>
                    </div>
                </div>

                <div className='d_view'>

                    <div className='iframe_p cq'>
                        <div style={{ width: '100%', height: '100px' }}>
                            {online ?
                                <DataView />
                                :
                                <div className='offline'>设备离线(请检查硬件)</div>
                            }
                        </div>
                    </div>
                    <div className='detail'>
                        <table>
                            <tr>
                                <td className='t'>名称</td>
                                <td colSpan={3}>{DeviceName || Title}</td>
                            </tr>
                            <tr>
                                <td className='t'>设备ID</td>
                                <td>{DeviceId}</td>
                                <td className='t'>序列号</td>
                                <td>
                                    {DisplayNo}
                                </td>
                            </tr>
                            <tr>
                                <td className='t'>状态</td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <svg t="1653636763670" className={!online ? 'offlineIcon' : 'onlineIcon'} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11787" ><path d="M60.8 1024c-33.6 0-60.8-27.2-60.8-60.8v-243.2c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v243.2c0 33.6-27.2 60.8-60.8 60.8zM361.6 1024c-33.6 0-60.8-27.2-60.8-60.8v-473.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v473.6c0 33.6-27.2 60.8-60.8 60.8zM662.4 1024c-33.6 0-60.8-27.2-60.8-60.8v-678.4c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v678.4c0 33.6-27.2 60.8-60.8 60.8zM963.2 1024c-33.6 0-60.8-27.2-60.8-60.8V60.8c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v902.4c0 33.6-27.2 60.8-60.8 60.8z" p-id="11788"></path></svg>
                                        <div className={!online ? 'offline' : 'online'} style={{ marginLeft: 4 }}>{online ? '在线' : '离线'}</div>
                                    </div>
                                </td>
                                <td className='t'>地理位置</td>
                                <td>{Location}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
